import React from "react";
import * as PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Helmet from "react-helmet";
import { rhythm } from "../../utils/typography";
import Layout from "../../components/layout";

const propTypes = {
  data: PropTypes.object.isRequired,
};

function getLangDir(locale) {
  const langDir = locale === "ja" ? "ja" : "en";
  return langDir;
}

class faqPage extends React.Component {
  render() {
    //const enNewsEdges = this.props.data.en.edges
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>FAQ │ Cookin’Burger</title>
          <meta
            name="description"
            content="New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!"
          />
        </Helmet>
        <div className="l-main">
          <div className="section-inner_m">
            <div
              className="breadcrumb-list wow fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="0.7s"
              data-wow-offset="0"
            >
              <ul>
                <li>
                  <Link to={`/en`}>TOP</Link>
                </li>
                <li>FAQ</li>
              </ul>
            </div>
          </div>

          <div className="section-inner_m">
            <div className="l-faq-section">
              <div
                className="l-term-section-title wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.7s"
                data-wow-offset="0"
              >
                FAQ
              </div>

              <div className="ctg-list">
                <ul>
                  <li>
                    <AnchorLink to="/en/faq#faq_cat_01" title="Overview" />
                  </li>
                  <li>
                    <AnchorLink to="/en/faq#faq_cat_02" title="Terminology" />
                  </li>
                  <li>
                    <AnchorLink to="/en/faq#faq_cat_03" title="Rules" />
                  </li>
                  <li>
                    <AnchorLink to="/en/faq#faq_cat_04" title="Scholarship" />
                  </li>
                  <li>
                    <AnchorLink to="/en/faq#faq_cat_05" title="Others" />
                  </li>
                </ul>
              </div>

              <h2 id="faq_cat_01">Overview</h2>
              <ul>
                <li>
                  <h3>Q.What is the"Cookin’ Burger"?</h3>
                  <p>
                    ”Cookin’ Burger” is a multitasking action game, which is
                    serving dishes quicklly and correctly as a burgershop staff
                    from the a various customers.
                    <br />
                    The concept is "Play to Earn" linked to crypto assets.
                    <br />
                    <br />
                    You can experience a simulated hamburger store management,
                    which is popular with around the world.
                    <br />
                    While the game seems simple, you are required to the actual
                    store management skills which is to learn the customers'
                    preferences or consider the efficient cooking order.
                  </p>
                </li>
                <li>
                  <h3>
                    Q.How can I make money by playing a game?
                  </h3>
                  <p>
                    In the game, you will obtain items that can be converted
                    into DEAPcoin, a type of crypto asset.
                    <br />
                    DEAPcoin can also be converted into other crypto assets and
                    legal tender (such as US dollars) via an exchange, so you
                    will find more opportunities to make money the more you
                    play.
                  </p>
                </li>
                <li>
                    <h3>Q.Please tell me how to make a wallet.</h3>
                    <p>
                        <a href="https://medium.com/playmining-game/how-to-create-a-playmining-id-and-wallet-c5628657047" target="_blank">https://medium.com/playmining-game/how-to-create-a-playmining-id-and-wallet-c5628657047</a>
                    </p>
                </li>
                <li>
                  <h3>Q.Is it free to play?</h3>
                  <p>
                    The game itself is free to play. However, in order to
                    purchase NFT, which grants a variety of in-game benefits, a
                    transaction using the crypto asset DEAPcoin will be
                    required. Note that any communication, connection, or other
                    such fees shall also be borne by the customer. <br />
                    <br />
                    There are three ways to obtain DEAPcoin:
                    <br />
                    1: Play the game *DEP will be rewarded when you clear certain conditions.
                    <br />
                    2: Purchase on the external crypto exchanges.
                    <br />
                    3. Purchase at PlayMining NFT with a credit card.<br />
                    <a href="https://daa.playmining.com/exchange" target="_blank">https://daa.playmining.com/exchange</a>
                  </p>
                </li>
                <li>
                  <h3>Q.Is the game age-restricted?</h3>
                  <p>
                    The game itself is not age-restricted.
                    <br />
                    However, crypto asset exchanges may independently set age
                    restrictions on crypto asset transactions.
                  </p>
                </li>
                <li>
                  <h3>Q.What are the game's system requirements?</h3>
                  <p>
                    System Requirements: <br />
                    [iOS] iPhone 7 or newer with iOS 11 or later. <br />
                    [Android] Android 5.0 or newer Recommended
                    <br />
                    Browsers: Chrome, Safari Connection
                    <br />
                    Speed: at least 10Mbps (both iOS and Android require a
                    minimum 2GB of memory)
                    <br />
                    *We do not guarantee the functionality of the above browsers
                    and devices. May not function correctly due to factors such
                    as network connection, browser version, device
                    specifications, and/or usage of the app.
                  </p>
                </li>
                <li>
                  <h3>
                    Q.When I close the browser, reload the page, or close the
                    tab or window I have the game open in, my game data
                    disappears.
                  </h3>
                  <p>
                    If you are using your browser's secret mode, your game
                    progress will not be saved.
                    <br />
                    Please ensure that you are not using this mode while playing
                    JobTribes.
                  </p>
                </li>
                <li>
                  <h3>Q.Game will close due to instability.</h3>
                  <p>
                    You may be using a browser other than the recommended
                    browser.
                    <br />
                    Please use Chrome or safari.
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_02">Terminology</h2>
              <ul>
                <li>
                  <h3>Q.What is blockchain?</h3>
                  <p>
                    It is a type of technology that is used to manage
                    transaction data for crypto assets.
                  </p>
                </li>
                <li>
                  <h3>Q.What is PlayMining?</h3>
                  <p>
                    It is a platform for creating a new economy and culture
                    based on the principle of "play to earn", through the use of
                    games and other entertainment mediums. It is centered around
                    PlayMining NFT, where NFT transactions are conducted using
                    the crypto asset DEAPcoin.
                  </p>
                </li>
                <li>
                  <h3>Q.What is DEAPcoin?</h3>
                  <p>
                    It is a crypto asset used in PlayMining NFT for NFT
                    transactions.
                    <br />
                    <br />
                    There are three ways to obtain DEAPcoin:
                    <br />
                    1. Play the game *DEP will be rewarded when you clear certain conditions.
                    <br />
                    2. Purchase on the external crypto exchanges.
                    <br />
                    3. Purchase at PlayMining NFT with a credit card.<br />
                    <a href="https://daa.playmining.com/exchange" target="_blank">https://daa.playmining.com/exchange</a>
                  </p>
                </li>
                <li>
                  <h3>Q.What does PlayMining NFT mean?</h3>
                  <p>
                    It is where NFT transactions using the crypto asset DEAPcoin
                    take place.
                  </p>
                </li>
                <li>
                  <h3>Q.What is Player Lv.?</h3>
                  <p>
                    When you play the game, you will acquire"EXP", then "EXP"
                    reaches a certain value, the "Player Lv."goes up.
                    <br />
                    Depends on "Player Lv.", you can open more stores with
                    Part-time Staff.
                  </p>
                </li>
                <li>
                  <h3>Q.What is Energy ?</h3>
                  <p>
                    Playing the game consumes "Energy".
                    <br />
                    "Energy" required for one game will be[12].
                    <br />
                    "Energy" will be restored once every [24] minutes, and the
                    upper limit will be [30].
                  </p>
                </li>
                <li>
                  <h3>Q.What is BurgerCoin ?</h3>
                  <p>
                    You can earn "BurgerCoins" for providing customer orders in
                    the game.
                    <br />
                    "BurgerCoins" are used to open the store with ith Part-time
                    Staff, or level up "Cookware Lv.", purchase "Items".
                  </p>
                </li>
                <li>
                  <h3>Q.What does Next DEAPcoin mean?</h3>
                  <p>
                    "DEAPcoin" which you earned during the season will be
                    accumulated once in the "Next DEAPcoin".
                    <br />
                    Accumulated "Next DEAPcoin" will be credited to your
                    "DEAPcoin" at the end of the season, and "Next DEAPcoin"
                    will be reset at the start of the new season.
                  </p>
                </li>
                <li>
                  <h3>Q.What does Chef's rank mean?</h3>
                  <p>
                    For providing customer orders, or maiking the burgers in the
                    same order as the recipe in the game, you acquire "Chef's
                    rank point".
                    <br />
                    When a certain value is reached, the "Chef's rank" goes up.
                    <br />
                    However "Chef's rank point" will decrease depending on what
                    you play, and if it falls below a certain value, your
                    "Chef's rank" will drop.
                    <br />
                    <br />
                    The "Chef's rank" are the following ranks.
                    <br />
                    There is a maximum "Chef's rank" depending on the category
                    of store you are playing.
                    <br />
                    <br />
                    .Trainee
                    <br />
                    .A Full-fledged Chef
                    <br />
                    .Bronze
                    <br />
                    .Silver
                    <br />
                    .Gold
                    <br />
                    .1 Star
                    <br />
                    .2 Stars:Truck/Secondhand
                    <br />
                    .3 Stars:Truck/Economy
                    <br />
                    .Master Chef: Truck/Deluxe
                  </p>
                </li>
                <li>
                  <h3>Q.What does Chef's rank point measn?</h3>
                  <p>
                    The following table shows the addition and subtraction of
                    "Chef's rank point" .<br />
                    <br />
                    ■Addition points
                    <br />
                    -Served the Food Very Quickly:The number of Speed bonus
                    <br />
                    -Made the Burger in Perfect Step:The number of Perfect bonus
                    <br />
                    -Maximum Combo
                    <br />
                    -Served the Food to All Customers
                    <br />
                    <br />
                    ■Subtraction points
                    <br />
                    -Threw the Ingredient in the Trash.
                    <br />
                    -The Customer has Left
                  </p>
                </li>
                <li>
                  <h3>Q.What is Store ?</h3>
                  <p>
                    The store will be used for games.
                    <br />
                    There are "Categories" of stores, and the "Number of
                    Visitors" , "Time limit," and "Recipes" vary depending on
                    the store categories .<br />
                    There are also "Japanese" "Western" and "Chinese" tastes,
                    each of which has its own "Recipes".
                  </p>
                </li>
                <li>
                  <h3>Q.What does Reputation Score mean?</h3>
                  <p>
                    For providing customer orders, or maiking the burgers in the
                    same order as the recipe in the game, you acquire
                    "Reputation Score point".
                    <br />
                    When a certain value is reached, the "Reputation Score
                    point" goes up.
                    <br />
                    <br />
                    However "Reputation Score poin" will decrease depending on
                    what you play, and if it falls below a certain value, your
                    "Reputation Score" will drop.
                    <br />
                    <br />
                    The "Number of Visitors" and the "content of orders"
                    increase in accordance with the "Reputation Score".
                  </p>
                </li>
                <li>
                  <h3>Q.What is Reputation Score point ?</h3>
                  <p>
                    The following table shows the addition and subtraction of
                    "Reputation Score point" .<br />
                    <br />
                    ■Addition points
                    <br />
                    -Served the Food Very Quickly:The number of Speed bonus
                    <br />
                    -Made the Burger in Perfect Step:The number of Perfect bonus
                    <br />
                    -Maximum Combo
                    <br />
                    -Served the Food to All Customers
                    <br />
                    <br />
                    ■Subtraction points
                    <br />
                    -Threw the Ingredient in the Trash
                    <br />
                    -The Customer has Left
                  </p>
                </li>
                <li>
                  <h3>Q.What is Recipe?</h3>
                  <p>
                    Each Store has its own "Recipe" and you can cook a menu of
                    "recipes" opened in the game.
                    <br />
                    Also, "Recipes" can be opened by "Reputation Score point".
                  </p>
                </li>
                <li>
                  <h3>Q.What does Cookware Lv. mean?</h3>
                  <p>
                    There are four types of "Cookware Lv." for each restaurant:
                    "Grill Lv," "Fryer Lv," "Juicer Lv," and "Dessert Lv."
                    Cooking speed increases according to the "Cookware Lv.”.
                    <br />
                    "Cookware Lv."can be raised by using "BurgerCoin" from
                    "Store Management".
                    <br />
                    The maximum "Cookware Lv." is limited to "Lv.
                  </p>
                </li>
                <li>
                  <h3>Q.What is Part-time Staff?</h3>
                  <p>
                    The store for the player who do not own the NFT store.{" "}
                    <br />
                    In the "Part-time Staffs”store, you will be abale to play
                    the game in various stores by releasing stores other than
                    the initially released "Cart/Secondhand" .<br />
                    To open stores, a certain amount of "Player Lv" and
                    "BurgerCoin" are required.
                    <br />
                    In addition, "Part-time Staff" stores cannot increase their
                    "Facility Lv".
                  </p>
                </li>
                <li>
                  <h3>Q.What is Repair Rank?</h3>
                  <p>
                    Repairing the durability value of store NFTs increases the repair value and the ""repair rank"".<br />
                    The ""Repair Rank"" varies depending on the type of store NFT."
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_03">Rules</h2>
              <ul>
                <li>
                  <h3>Q.What are the requirements to complete the game?</h3>
                  <p>
                    The game is cleared when all customer orders are served
                    within the time limit.
                    <br />
                    If the time limit expires, you will receive "BurgerCoin" and
                    "Score" up to that point.
                  </p>
                </li>
                <li>
                  <h3>Q.What is ”Speed bonus”?</h3>
                  <p>
                    "speed bonus" is awarded for serving all dishes within the
                    order time.
                    <br />
                    The faster you serve the food, the more "Speed Bonus" you
                    will receive, and you can aim for higher scores.
                  </p>
                </li>
                <li>
                  <h3>Q.What is ”Perfect bonus”?</h3>
                  <p>
                    When the burger is made according to the recipe, "Perfect
                    Bonus" will be awarded.
                  </p>
                </li>
                <li>
                  <h3>Q.What is ”Combo bonus”?</h3>
                  <p>
                    "Combo Bonus" is generated by continuing to serve food
                    within a short period of time. While the "Combo Bonus" is in
                    effect, players can aim for a higher score by accumulating
                    combos.
                    <br />
                    The "Combo Bonus" has a time limit, and the "Combo Bonus"
                    will be reset when the word "COMBO" disappears.
                    <br />
                    The more combos you have, the shorter the time limit for
                    combos, so the key to a high score is to make good use of
                    the "tray" to maintain the "Combo Bonus".
                    <br />
                    <br />
                    Translated with www.DeepL.com/Translator (free version)
                  </p>
                </li>
                <li>
                  <h3>Q.What is ”BurgerCoin(Tip)”?</h3>
                  <p>
                    After completing a customer's order, the customer leaves
                    with tips (="BurgerCoin").
                    <br />
                    By tapping ”BurgerCoin” you receive it and can call a new
                    customer.
                  </p>
                </li>
                <li>
                  <h3>Q.Who is "VIP" and "Super VIP" ?</h3>
                  <p>
                    During the game, there is a low probability that a "VIP" or
                    "Super VIP" will visit your store.
                    <br />
                    By processing the orders of these "VIPs" and "Super VIPs",
                    "BurgerCoin" and "DEAPcoin" will be placed.
                    <br />
                    When a "VIP" or "Super VIP" comes to your store, be careful
                    not to send him or her home.
                  </p>
                </li>
                <li>
                  <h3>Q.What is ”Trash Can”?</h3>
                  <p>
                    If you burn food cooked on the "grill" or "fryer," or if you
                    make a mistake cooking a burger and want to start over, you
                    can throw away the food by taking it to the "trash can"
                    located in the lower left corner.
                    <br />
                    <br />
                    However, the number of times you throw away ingredients in
                    the "trash can" will negatively affect your "Chef's rank"
                    and "Reputation Score" so be careful not to lose any food.
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_04">Scholarship</h2>
              <ul>
                <li>
                  <h3>Q.What is ”Scholarship” function?</h3>
                  <p>
                    By lending your NFTs to a "Scholar" (another player), you
                    can earn DEAPcoin obtained by the "Scholar".
                    <br />
                    The Scholarship function will only provide the ability to
                    lend and borrow "Stores" as NFT and consolidate the DEAPcoin
                    obtained by Scholar under Owner.
                    <br />
                    <br />
                    The "Cookin' Burger" management team will not be involved in
                    the exchange of rewards between the "Owner" and "Scholar".
                    Please do so at your own risk.
                  </p>
                </li>
                <li>
                  <h3>Q.What does ”Owner” mean?</h3>
                  <p>
                    A player who lends NFTs is called an "Owner".
                    <br />
                    The "Owner" can also make "Scholarshipagreements" with as
                    many NFTs as she/he owns.
                    <br />
                    However, only one "Scholarship" is allowed for the same
                    "ScoLar", and multiple "Scholarship" are not allowed.
                  </p>
                </li>
                <li>
                  <h3>Q.What does ”Scalar” mean?</h3>
                  <p>
                    A player who borrows an NFT from an "Owner" is called a
                    "Scholar.
                    <br />
                    A "Scholarship" can only be entered into with one "Owner".
                    <br />
                    If a new "Scholarship" is to be made, the current
                    "Scholarship" must be terminated.
                  </p>
                </li>
                <li>
                  <h3>
                    Q.What happens to the items and DEAPcheck which earned
                    in-game when using Scholarship?
                  </h3>
                  <p>
                    All items earned in-game, except DEAPcoin, will be sent to
                    Scholar's gift box as usual and can be earned as is.
                    <br />
                    However, all DEAPcoin earned in-game during the Scholar
                    period will be sent to the Owner.
                    <br />
                    The DEAPcoins sent to the Owner will include those obtained
                    through tips and other means.
                  </p>
                </li>
                <li>
                  <h3>Q.How to terminate Scholarship?</h3>
                  <p>
                    There is a button for cancellation is provided in"To the
                    Owner Screen" > "The Scalars List" > "Details"
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_05">Others</h2>
              <ul>
                <li>
                  <h3>Q.What is a PlayMining ID?</h3>
                  <p>
                    It is an ID required to use services provided by Digital
                    Entertainment Asset Pte. Ltd.
                    <br />
                    Currently, it is used for PlayMining NFT (
                    <a href="https://daa.playmining.com/" target="_blank">
                      https://daa.playmining.com/
                    </a>
                    ) and Cookin' Burger.
                  </p>
                </li>
                <li>
                  <h3>Q.What does Register mean?</h3>
                  <p>
                    Register refers to the acquisition of a PlayMining ID.<br />
                    An e-mail address is all that is required to acquire a PlayMining ID.<br />
                    On the PlayMining NFT "Sign-up" page, please agree to the Terms of Use and enter your email address. After receiving a one-time code in your mailbox, please enter the code and register your password to complete your registration.
                  </p>
                </li>
                <li>
                  <h3>Q.What does Log In mean?</h3>
                  <p>
                    In order to continue playing with the "Cookin' Burger" data stored on the server, you are required to log in.<br />
                    To log in from a logged out state, please enter your email address to obtain a one-time code. Login will be completed by entering the one-time code and the password.<br />
                    *When you log in, you will be transferred from Cookin' Burger to PlayMining NFT.
                  </p>
                </li>
                <li>
                  <h3>Q.I can't Log In.</h3>
                  <p>
                    Please make sure that you have entered the registered email address correctly.
                    <br />
                    Also, please confirm that you have entered the correct one-time code from the email and that your password is accurate.
                  </p>
                </li>
                <li>
                  <h3>
                    Q.Even though I've registered my PlayMining ID, the game
                    starts from language select.
                  </h3>
                  <p>
                    If you've already registered a PlayMining ID and it still
                    prompts you to select a language, select Log In by tapping
                    the Support button at the bottom-left of the title screen
                    when the game starts, and input your e-mail address.
                    Starting the game by using the link in the e-mail you
                    receive will enable you to continue playing.
                  </p>
                </li>
                <li>
                  <h3>Q.I can't find an NFT I own the NFT for.</h3>
                  <p>
                    it may not be possible to obtain NFT information during
                    maintenance of PlayMining NFT.
                    <br />
                    Please check the operating status of PlayMining NFT and wait
                    until the maintenance is completed.
                  </p>
                </li>
                <li>
                  <h3>Q.Opening the game in multiple tabs.</h3>
                  <p>
                    When registering a PlayMining ID or logging in using a URL,
                    the game will open in a tab separate from the one you were
                    using before.
                    <br />
                    As long as the tabs are in the same browser, you can still
                    play the game.
                    <br />
                    However, your browser may become unstable with multiple tabs
                    open, so please close any unnecessary tabs while playing.
                  </p>
                </li>
                <li>
                  <h3>Q.About Data Transfers</h3>
                  <p>
                    As this is a browser game, you can continue playing your
                    game on any device simply by logging in with your PlayMining
                    ID. We recommend linking your PlayMining ID to avoid losing
                    data when clearing the cache.
                  </p>
                </li>
                <li>
                  <h3>Q.Terms of Use</h3>
                  <p>
                    Please click on the link below to read the Terms of Use for
                    this work.
                    <br />
                    <a
                      href="https://www.cookinburger.com/en/terms/"
                      target="_blank"
                    >
                      https://www.cookinburger.com/en/terms/
                    </a>
                  </p>
                </li>
                <li>
                  <h3>Q.Privacy Policy</h3>
                  <p>
                    You can access the Privacy Policy for this game via the link
                    below.
                    <br />
                    <a href="https://dea.sg/poricy/" target="_blank">
                      https://dea.sg/poricy/
                    </a>
                  </p>
                </li>
                <li>
                  <h3>Q.Contact Us</h3>
                  <p>
                    If this FAQ does not resolve your issue, please contact us
                    via the e-mail address below.
                    <br />
                    <a href="mailto:support@burgerstudio.jp?subject=To Cookin' Burger Support【EN】" target="_blank">
                      support@burgerstudio.jp
                    </a>
                    <br />
                    Please be aware that we cannot respond to questions
                    regarding game strategy.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

faqPage.propTypes = propTypes;

export default faqPage;

export const pageQuery = graphql`
  query FaqEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
